import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no 5:30pm class today. We will close at 5:30 to set
up for the Granite Games competition.`}</em></strong></p>
    <p>{`Deadlifts 4×4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 200 Single Unders`}</p>
    <p>{`42-Wall Balls (20/14)`}</p>
    <p>{`21-SDHP (95/65)`}</p>
    <p>{`30-Wall Balls`}</p>
    <p>{`15-SDHP`}</p>
    <p>{`18-Wall Balls`}</p>
    <p>{`9-SDHP`}</p>
    <p>{`Cash Out: 200 Single Unders`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up this Saturday!  Come out
and cheer on all our teams!  We will be closing after the 4:30 class
today to set up.  If you can help out please let us know, it will be
greatly appreciated.`}</strong></p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s (11:15) and Sunday’s (1:45)
each weekend after our CrossFit classes.  Email Daniel for more
information.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      